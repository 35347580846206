export function list(params = null) {
    return axios({
        url: `/api/transactions/list`,
        method: 'get',
        params: params,
    });
}

export function excel(query) {
    return axios.post(`/api/transactions/excel`, query, {
        responseType: 'blob'
    });
}

export function partnerExcel(query) {
    return axios.post(`/api/transactions/excel-partner`, query, {
        responseType: 'blob'
    });
}

export function partnerList(params = null) {
    return axios({
        url: `/api/transactions/partner-list`,
        method: 'get',
        params: params,
    });
}
