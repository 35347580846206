<template>
    <div>
        <div class="score-history__item" v-if="item.amount > 0">
            <div class="column"><div class="write-on">Нарахування</div></div>
            <div class="column">{{ item.createdAt }}</div>
            <div class="column">{{ item.userFullName }}</div>
            <div class="column"><div class="write-on-amount">{{ item.amount }}</div></div>
            <div class="column">{{ item.currentAmount }}</div>
            <div class="column"><span>Продукт:</span> Оплата штрафу({{item.protocol}})</div>
            <div class="column"></div>
            <div class="column" ><span>Джерело:</span>Заробіток за сплату штрафу</div>
<!--            <div class="column"><span>ID транзакції:</span> {{ item.id }}</div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'RowBlockPaymentPenalty',
    props: {
        item: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    amount: null,
                    createdAt: null,
                    currentAmount: null,
                };
            },
        },
    },
}
</script>
