<template>
    <div class="score-history__item">
        <div class="column"><div class="write-off">Списання</div></div>
        <div class="column">{{ item.createdAt }}</div>
        <div class="column"></div>
        <div class="column"><div class="write-off-amount">-{{ item.amount }}</div></div>
        <div class="column">{{ item.currentAmount }}</div>
        <div class="column"><span>Продукт:</span>Списання комісійних винагород за тестовий договір ОСЦПВ ({{ item.mtsbuCode }})</div>
        <div class="column"></div>
        <div class="column"><span>Джерело:</span> Автосписання</div>
        <div class="column"><span>ID транзакції:</span> {{ item.id }}</div>
    </div>
</template>

<script>
export default {
    name: 'RowBlockWritingOffCommissionTestOsago',
    props: {
        item: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    amount: null,
                    createdAt: null,
                    currentAmount: null,
                };
            },
        },
    },
}
</script>
