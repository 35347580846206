<template>
    <div class="score-history__item">
        <div class="column"><div class="write-on"> Поповнення</div></div>
        <div class="column"> {{ item.createdAt }}</div>
        <div class="column">{{ item.userFullName }}</div>
        <div class="column"><div class="write-on-amount"> {{ item.amount }}</div></div>
        <div class="column"> {{ item.currentAmount }}</div>
        <div class="column" v-if="item.orderType == 1"><span>Продукт:</span>ОСЦПВ ({{ item.mtsbuCode }})</div>
        <div class="column" v-else-if="item.orderType == 2"><span>Продукт:</span>Автобус ({{ item.contractId }})</div>
        <div class="column" v-else-if="item.orderType == 3"><span>Продукт:</span>ЗК ({{ item.mtsbuCode }})</div>
        <div class="column"><span v-if="item.company">СК:</span> {{ item.company }}</div>
        <div class="column"><span>Джерело:</span> Поповнення через Monobank</div>
        <div class="column"><span>ID транзакції:</span> {{ item.id }}</div>

    </div>
</template>

<script>
export default {
    name: 'RowBlockInvoiceForClient',
    props: {
        item: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    amount: null,
                    createdAt: null,
                    currentAmount: null,
                };
            },
        },
    },
}
</script>
