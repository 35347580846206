<template>
    <div class="score-history__item">
        <div class="column">
            <PuSkeleton  width="70px" class="skeleton-input"></PuSkeleton>
        </div>
        <div class="column">
            <PuSkeleton  width="90px" class="skeleton-input"></PuSkeleton>
        </div>
        <div class="column">
            <PuSkeleton  width="70px" class="skeleton-input"></PuSkeleton>
        </div>
        <div class="column">
            <PuSkeleton  width="80px" class="skeleton-input"></PuSkeleton>
        </div>
        <div class="column">
            <PuSkeleton  width="80px" class="skeleton-input"></PuSkeleton>
        </div>
    </div>
</template>

<script>
export default {
    name: 'row-block-skeleton',
}
</script>

<style lang="scss" scoped>
    .skeleton-input {
        display: initial !important;
    }
    .pu-skeleton {
        display: initial !important;
    }
</style>
