<template>
    <div class="score-history__item">
        <div class="column"><div class="write-on">Нарахування</div></div>
        <div class="column">{{ item.createdAt }}</div>
        <div class="column"></div>
        <div class="column"><div class="write-on-amount">{{ item.amountDgo }}</div></div>
        <div class="column">{{ item.currentDgoAmount }}</div>
        <div class="column"><span>Продукт:</span> Повернення коштів за тестовий договір ДЦВ ({{ item.mtsbuCode }})</div>
        <div class="column"></div>
        <div class="column"><span>Джерело:</span> Автоповнення</div>
        <div class="column"><span>ID транзакції:</span> {{ item.id }}</div>
    </div>
</template>

<script>
export default {
    name: 'RowBlockRefundFundsTestContract',
    props: {
        item: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    amount: null,
                    createdAt: null,
                    currentAmount: null,
                };
            },
        },
    },
}
</script>
