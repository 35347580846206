<template>
    <div class="container pl-0" style="margin-bottom: 30px;">
        <div class="insurance-history__menu__filters" >
            <button @click="filterStatusWrap()" style="width: 120px;text-align: left">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="4" width="24" height="2" fill="#24282C"/>
                    <rect y="18" width="24" height="2" fill="#24282C"/>
                    <circle cx="8" cy="5" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
                    <circle cx="16" cy="19" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
                </svg>
                {{ condition.isShowFilter ? 'Приховати' : 'Фільтри'}}
            </button>
            <button v-if="! isLoadData && (items && items.length > 0)" @click="getXls()">
                <div v-if="isLoadXml"
                    class="option-loader spinner-border"
                    style="height: 22px; width: 22px; margin-right: 12px;"
                    role="status">
                </div>
                <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="3" fill="#207245"/>
                    <path d="M6.16667 4.5C5.24167 4.5 4.5 5.24167 4.5 6.16667V17.8333C4.5 18.7583 5.24167 19.5 6.16667 19.5H17.8333C18.7583 19.5 19.5 18.7583 19.5 17.8333V6.16667C19.5 5.24167 18.7583 4.5 17.8333 4.5H6.16667ZM6.16667 6.16667H17.8333V17.8333H6.16667V6.16667ZM12.8333 12L15.5 16.1667H13.8333L12 13L10.1667 16.1667H8.5L11.1667 12L8.5 7.83333H10.1667L12 11L13.8333 7.83333H15.5L12.8333 12Z" fill="white"/>
                </svg>
                Експорт в Excel
            </button>
        </div>
        <transition v-if="isMainHistory" name="fade">
            <div v-show="condition.isShowFilter" class="menu filters mt-4">
            <div class="row">
                <div class="col-sm-4 form-group">
                    <div class="filter-title" style="">
                        Тип операції
                    </div>
                    <el-select clearable  v-model="listQuery.typeId"
                               class="form-control-el"
                               placeholder="Виберiть тип операцiї">
                        <el-option :key="accountCashHistoryInfo.mainTypes['writeOff']"
                                   label="Cписання"
                                   :value="accountCashHistoryInfo.mainTypes['writeOff']">
                        </el-option>
                        <el-option :key="accountCashHistoryInfo.mainTypes['replenishment']"
                                   label="Поповнення"
                                   :value="accountCashHistoryInfo.mainTypes['replenishment']">
                        </el-option>
                        <el-option :key="accountCashHistoryInfo.mainTypes['accrual']"
                                    label="Нарахування"
                                    :value="accountCashHistoryInfo.mainTypes['accrual']">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-sm-4 form-group">
                    <div class="filter-title">ID</div>
                    <div class="form-group">
                        <input class="form-control" v-model="listQuery.id">
                    </div>
                </div>
                <div class="col-sm-4 form-group">
                    <div class="filter-title">
                        Дата
                    </div>
                    <el-date-picker
                        class="form-control-el w-100"
                        v-model="dateFilter"
                        type="daterange"
                        align="right"
                        format="dd-MM-yyyy"
                        start-placeholder="Від"
                        :picker-options="pickerOptions"
                        end-placeholder="До"
                        @change="refreshDate"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 form-group">
                    <div class="filter-title">МТСБУ код</div>
                    <div class="form-group">
                        <input class="form-control" v-model="listQuery.mtsbuCode">
                    </div>
                </div>
                <div class="col-sm-4 form-group">
                    <div class="filter-title" style="">
                        Компанія
                    </div>
                    <el-select v-model="listQuery.companyIds"
                               class="form-control-el"
                               multiple placeholder="Виберіть компанію">
                        <el-option v-for="item in companiesList"
                                   :key="item.id"
                                   :label="item.companyName"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
                <div class="row" v-if="! isPartner()">
                    <div class="col-sm-6 form-group">
                        <div class="filter-title">
                            Торгова точка
                        </div>
                        <el-select v-model="listQuery.partnerIds"
                                   class="form-control-el"
                                   multiple
                                   placeholder="Виберіть торгову точку">
                            <el-option v-for="item in partnersList"
                                       :key="item.id"
                                       :label="item.fullName"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            <hr>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <button @click="getList()" class="btn pl-0 w-100 btn-primary">
                        Застосувати
                    </button>
                </div>
            </div>
        </div>
        </transition>
        <transition v-else name="fade">
            <div v-show="condition.isShowFilter" class="menu filters mt-4">
            <div class="row" v-if="! isPartner()">
                <div class="col-sm-6 form-group">
                    <div class="filter-title">
                        Торгова точка
                    </div>
                    <el-select v-model="listQuery.invoicePartnerIds"
                               class="form-control-el"
                               multiple
                               placeholder="Виберіть торгову точку">
                        <el-option v-for="item in partnersList"
                                   :key="item.id"
                                   :label="item.fullName"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-sm-4 form-group">
                    <div class="filter-title">
                        Дата
                    </div>
                    <el-date-picker
                        class="form-control-el w-100"
                        v-model="dateFilter"
                        type="daterange"
                        align="right"
                        format="dd-MM-yyyy"
                        start-placeholder="Від"
                        :picker-options="pickerOptions"
                        end-placeholder="До"
                        @change="refreshDate"
                    />
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <button @click="getList()" class="btn pl-0 w-100 btn-primary">
                        Застосувати
                    </button>
                </div>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
    import { userMixin, partnerMixin, companyMixin, orderMixin } from '@/mixin';
    import {list as listCompanies} from "@/api/company";

    export default {
        mixins: [userMixin, partnerMixin, companyMixin, orderMixin],
        name: 'FilterBlock',
        props: ['isLoadXml', 'isLoadData', 'items', 'isMainHistory'],
        data: function () {
            return {
                dateFilter: new Date(),
                condition: {
                    isShowBlock: false,
                    isShowFilter: false,
                    isShowChildrenUser:false
                },
                listDefaultQuery: {
                    id: null,
                    typeId: null,
                    mtsbuCode: null,
                    partnerIds: null,
                },
                listQuery: {
                    id: null,
                    typeId: null,
                    mtsbuCode: null,
                    partnerIds: null,
                    toDate: moment().calendar(),
                    fromDate: moment().subtract(31, 'days').calendar(),
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            }
        },
        created() {
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 31);
            oneWeekAgo.setHours(0, 0, 0, 0);
            this.dateFilter = [oneWeekAgo, moment()];

            this.listQuery.fromDate = moment(this.dateFilter[0]).format();
            this.listQuery.toDate =  moment(this.dateFilter[1]).format();
        },
        mounted: function () {

        },
        computed: {
            accountCashHistoryInfo: function () {
                return this.$store.getters.ACCOUNT_CASH_HISTORY
            },
            partnersList() {
                return this.$store.getters.ALL_PARTNERS;
            },
            orderStatuses() {
                return this.$store.getters.ORDER_STATUSES;
            }
        },
        methods: {
            changeStatusId(statusId) {
                this.listQuery.sta
            },
            getQuery() {
                this.listQuery.fromDate = moment(this.dateFilter[0]).format();
                this.listQuery.toDate =  moment(this.dateFilter[1]).format();
                this.listQuery.toDate =  moment(this.dateFilter[1]).format();

                return this.listQuery;
            },
            refreshDate: function() {
                this.listQuery.fromDate = moment(this.dateFilter[0]).format();
                this.listQuery.toDate =  moment(this.dateFilter[1]).format();
            },
            filterStatusWrap: function(scroll = false) {
                this.condition.isShowFilter = ! this.condition.isShowFilter;

                if (scroll) {
                    let filter = this.$el.querySelector(".insurance-history__menu__filters");
                    filter.scrollTop = filter.scrollHeight;
                }
            },
            getXls : function() {

                return this.$emit('download-xls');
            },
            getList() {
                return this.$emit('init-orders-list');
            },
            initOrdersList: function() {
                return this.$emit('init-orders-list');
            }
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
</style>
